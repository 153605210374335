import React from 'react'
import {Link} from 'react-router-dom'

import logo from '../logo.svg';
import '../App.css'
import { withTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";


function  OurPlus() {
  const { t } = useTranslation();    

  return(
    <section className="section position-relative">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-title">{t("Our abilities")}</h2>
        </div>
        <div className="col-lg-6 col-md-6 mb-80">
          <div className="d-flex">
            <div className="mr-lg-5 mr-3">
              <i className="ti-desktop icon icon-light icon-bg bg-white shadow rounded-circle d-block"></i>
            </div>
            <div>
              <h4>{t("Educational Webinar Platform")}</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-80">
          <div className="d-flex">
            <div className="mr-lg-5 mr-3">
              <i className="ti-medall icon icon-light icon-bg bg-white shadow rounded-circle d-block"></i>
            </div>
            <div>
              <h4>{t("International certification")}</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-80">
          <div className="d-flex">
            <div className="mr-lg-5 mr-3">
              <i className="ti-view-list-alt icon icon-light icon-bg bg-white shadow rounded-circle d-block"></i>
            </div>
            <div>
              <h4>{t("Around 9 fields of study")}</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-80">
          <div className="d-flex">
            <div className="mr-lg-5 mr-3">
              <i className="ti-book icon icon-light icon-bg bg-white shadow rounded-circle d-block"></i>
            </div>
            <div>
              <h4>{t("Training to over 115 courses")}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img className="img-fluid edu-bg-image w-100" src="images/backgrounds/education-bg.png" alt="bg-image" />
  </section>
  );
}

export default OurPlus;