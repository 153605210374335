import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import axios from "axios";
import { useTranslation } from "react-i18next";

function Popular({match}) {
  const { t } = useTranslation();

  var i;
  var divs = document.getElementsByTagName('p');
  for(i=0;i<divs.length;i++) {
    if(divs[i].className == 'cars-text') {
      divs[i].innerHTML = divs[i].innerHTML.substring(0,80);
    }
  }

  const [event, setData] = useState([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://8t-corporation.com/api/most/`
    }).then(response => {
      setData(response.data)
    })
  });
  {
    return (
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="section-title">{t("Events and activities")}</h2>
              </div>

              {event.map (c =>(
              <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow">
                  <img className="rounded card-img-top" src={c.mer.image} alt="post-thumb"/>
                  <div className="card-body">
                    <h4 className="card-title"><a className="text-dark" href="blog-single.html">{c.mer.name}</a>
                    </h4>
                    <p className="cars-text">{c.mer.description}</p>
                    <Link to = {{pathname: `post-detail/${c.mer.id}`, fromDashboard: false}}
                          className="btn btn-xs btn-primary">{t("Read more")}</Link>
                  </div>
                </article>
              </div>
                  ))}


            </div>
          </div>
        </section>

    )
  }
}


export default Popular;