import React from 'react'
import axios from "axios";
import {Form} from 'react-bootstrap'
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

class Footer extends React.Component{

    constructor(props) {
    super(props);
    this.state = {
        fio: '',
        number: '',
        email: '',
        course: this.props.course




    };

    this.handleChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }


onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    async getData(){

}
handleSubmit(event)  {

     axios({
            method: "POST",
            url: `https://8t-corporation.com/api/zayavka/`,
            data: {
              fio: this.state.name,
              email: this.state.email,
              number: this.state.number,
              course: this.props.course

            },
        

        }).then(res => {
                 this.state.data = res.data
                console.log(this.state.data)
                window.alert('Заявка на курс отправлена!')




    }).catch(function (error) {
        console.log(error);

    });
    event.preventDefault();


}


    render(){
        return(
            <div>
            <section className="section section-on-footer" data-background="images/backgrounds/bg-dots.png">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <h2 className="section-title"></h2>
      </div>
      <div className="col-lg-8 mx-auto">
        <div className="bg-white rounded text-center p-5 shadow-down">
          <h4 className="mb-80">Оставить заявку на курс</h4>
          <form  className="row">
            <div className="col-md-6">
              <input type="text"  onChange = {this.onChange} value = {this.state.name}  name="name" placeholder="Ф.И.О." className="form-control px-0 mb-4" />
            </div>
            <div className="col-md-6">
              <input type="email" name="email"  onChange = {this.onChange} value = {this.state.email} placeholder="Email" className="form-control px-0 mb-4" />
            </div>
            <div className="col-12">

            </div>
            <div className="col-12">
            <input type="text" name="number" onChange = {this.onChange} value = {this.state.number} placeholder="Номер телефона" className="form-control px-0 mb-12" />
         

            </div>
            <div className="col-lg-6 col-10 mx-auto mt-5">
              <button  onClick = {this.handleSubmit} type='submit' className="btn btn-primary w-100">Отправить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<footer className="bg-dark footer-section">
  <div className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <h5 className="text-light">Email</h5>
          <p className="text-white paragraph-lg font-secondary">elab.shakha@gmail.com</p>
          <p className="text-white paragraph-lg font-secondary">elab.samirr@gmail.com</p>
        </div>
        <div className="col-md-4">
          <h5 className="text-light">Контактные данные</h5>
          <p className="text-white paragraph-lg font-secondary">+7 (776) 149 2925</p>
          <p className="text-white paragraph-lg font-secondary">+7 (705) 319 9185</p>
        </div>
        <div className="col-md-4">
          <h5 className="text-light">Адрес</h5>
          <p className="text-white paragraph-lg font-secondary">Казахстан, г. Алматы, Ауэзовский район, Аксай 5, Дом 25</p>
        </div>
        <div className="col-md-4">
          <h5 className="text-light">Мы в Соц.сетях: </h5>
          <a className="text-white" href="https://www.youtube.com/channel/UCjwLOs7tgaZ1ArqTv10r8Zw" target="_blank"><i className="ti-youtube"></i></a>
          <a href="https://www.youtube.com/channel/UCjwLOs7tgaZ1ArqTv10r8Zw" target="_blank" className="text-white paragraph-lg font-secondary"> <u>Youtube</u><br/></a>
          <a className="text-white" href="https://www.instagram.com/elab.asia/" target="_blank" ><i className="ti-instagram"></i></a>
          <a href="https://www.instagram.com/elab.asia/" target="_blank" className="text-white paragraph-lg font-secondary"> <u>Instagram</u></a>
        </div>
      </div>
    </div>
  </div>
  <div className="border-top text-center border-dark py-5">
    <p className="mb-0 text-light">Copyright ©<script>
        var CurrentYear = new Date().getFullYear()
        document.write(CurrentYear)
      </script> by <a href="/">elab.asia</a></p>
  </div>
</footer>

</div>

)
    }
}

export default Footer;