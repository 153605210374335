import React from 'react'
import Navbar from '../components/navbar';
import OurPartners from '../components/ourPartners';
import OurPlus from '../components/ourPlusSection';
import OurReviews from '../components/ourReviews';
import OurWork from '../components/ourWorkSection';
import Welcome from '../components/welcomeSection';
import Popular from "../components/PopularMer";
import Footer2 from '../components/Footer2';
import { useTranslation } from "react-i18next";

function Home({ onLanguageChange }) {
  
  const { t } = useTranslation();

  return(

<div>
  
  <Navbar onLanguageChange={onLanguageChange} />
  <Welcome />

  <section className="section">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto text-center">
          <p className="font-secondary paragraph-lg text-dark" >{ t("home_about_elab_1") }</p>
        </div>
      </div>
    </div>
  </section>

  <OurWork />

  <OurPlus />


  <OurReviews />

  <OurPartners />

  <Popular/>

  <Footer2 />
</div>
        );
    

}

export default Home;