import React from 'react'
import '../App.css';
import { withTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";


function  OurWork() {
  const { t } = useTranslation();    
    
        return(
<section className="section">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <h2 className="section-title">{ t("what_are_we_doing") }</h2>
      </div>
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="card hover-shadow shadow">
          <div className="card-body text-center px-4 py-5">
            <i className="ti-bar-chart icon mb-5 d-inline-block"></i>
            <h4 className="mb-4">{ t("Digital Develepment") }</h4>
            <p>{ t("Digital Develepment TXT") }</p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="card active-bg-primary hover-shadow shadow">
          <div className="card-body text-center px-4 py-5">
            <i className="ti-stats-up icon mb-5 d-inline-block"></i>
            <h4 className="mb-4"> { t("Teacher's Skills Development") }</h4>
            <p>{ t("Teacher's Skills Development TXT") }</p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="card hover-shadow shadow">
          <div className="card-body text-center px-4 py-5">
            <i className="ti-heart icon mb-5 d-inline-block"></i>
            <h4 className="mb-4">{t("Realization of the social projects")}</h4>
            <p>{t("Realization of the social projects TXT")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        );
    
}

export default OurWork;