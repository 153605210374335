import React from 'react'
import {Link} from 'react-router-dom'
import './courseItem.css'

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } }); 

class Courseitem extends React.Component {

    render(){
        var c = this.props.item;
        return(
<div className="col-lg-4 col-6 mb-4 shuffle-item" data-groups={JSON.stringify(c.group)}>
<div className="position-relative rounded" style={{ backgroundColor: '#fcfcfc' }}>
<img src={c.image} alt="portfolio-image" className=" rounded w-100 d-block"/>
<div style={{padding: '10px 20px'}}>

<div style={{ height:"190px", overflow:"hidden", paddingBottom:"10px" }}>
    <span style={{color: 'black', fontSize: 18, fontWeight: 'bold'}}>{c.name}</span>
    <div style={{color: 'black', fontSize: 14, padding: '10px 0 20px 0', lineHeight:'15px'}}>{renderHTML(c.descr)}</div>
</div>

<span className="dur">{c.duration}</span>
<span className="price">{c.price_txt}</span>

<hr/>
<Link className="btn btn-secondary btn-sm"  to = {{pathname: `/course-detail/${c.id}`}}>Подробнее</Link>
</div>
</div>
</div>
        )
    }
}

export default Courseitem;