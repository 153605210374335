import React, {useEffect, useState} from 'react'
import Navbar from '../components/navbar';
import Footer2 from '../components/Footer2';
import OurPartners from '../components/ourPartners';
import './freecourse.css';
import ReactPlayer from "react-player"
import fc_clock from './../assets/images/fc_clock.png'
import fc_cal from './../assets/images/fc_cal.png'
import fc_cert from './../assets/images/fc_cert.png'
import fc_tenge from './../assets/images/fc_tenge.png'
import fc_spec from './../assets/images/fc_spec.png'
import { useTranslation } from "react-i18next";

function Freecourse({ onLanguageChange }) {

  const { t } = useTranslation();

  const FacilityStyle = {
    padding:"10px 0 10px 30px"
  }

  const fc_clockStyle = { backgroundImage: 'url(' + fc_clock + ')' }
  const fc_calStyle = { backgroundImage: 'url(' + fc_cal+ ')' }
  const fc_certStyle = { backgroundImage: 'url(' + fc_cert+ ')' }
  const fc_tengeStyle = { backgroundImage: 'url(' + fc_tenge+ ')' }
  const fc_specStyle = { backgroundImage: 'url(' + fc_spec+ ')' }

  return(

<div className="freecourse-page">
    
  <Navbar onLanguageChange={onLanguageChange} />

  <section className="page-title bg-primary position-relative">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <div className="text-white font-tertiary montserrat" style={{fontSize:"46px",fontWeight:"bold", textTransform:"uppercase"}} dangerouslySetInnerHTML={{__html:t("FREE DIGITAL COURSES FOR CHILDREN")}}></div>
      </div>
    </div>
  </div>
  <img src="/images/illustrations/page-title.png" alt="illustrations" className="bg-shape-1 w-100" />
  <img src="/images/illustrations/cSharp.png" alt="illustrations" className="bg-shape-2" style={{maxWidth: '10%'}} />
  <img src="/images/illustrations/dots-cyan.png" alt="illustrations" className="bg-shape-3" />
  <img src="/images/illustrations/leaf-orange.png" alt="illustrations" className="bg-shape-4" />
  <img src="/images/illustrations/html.png" alt="illustrations" className="bg-shape-5" style={{WebkitTransform: 'rotate(0deg)', transform: 'rotate(0deg)', maxWidth: '10%'}} />
  <img src="/images/illustrations/dots-group-cyan.png" alt="illustrations" className="bg-shape-6" />
  <img src="/images/illustrations/java.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{right: -0, bottom: -60, maxWidth: '10%'}}/>
  <img src="/images/illustrations/racho.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{left: 200, top: 150, maxWidth: '10%'}}/>
  <img src="/images/illustrations/bars.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{left: 550, top: 10, maxWidth: '10%'}}/>
  <img src="/images/illustrations/varna.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{right: 40, bottom: 200, maxWidth: '20%'}}/>
</section>

  <section className="section">
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          <ul className="freecourse_attr">
            <li style={fc_clockStyle} dangerouslySetInnerHTML={{__html:t("36 hours")}}></li>
            <li style={fc_calStyle} dangerouslySetInnerHTML={{__html:t("3 months, 2 sessions per week")}}></li>
            <li style={fc_certStyle} dangerouslySetInnerHTML={{__html:t("After completion of the program you are getting CERTIFICATE OF APPROVAL")}}></li>
            <li style={fc_tengeStyle} dangerouslySetInnerHTML={{__html:t("Payment: no charge")}}></li>
            <li style={fc_specStyle} dangerouslySetInnerHTML={{__html:t("The courses are conducted by highly qualified professionals in the field of digital technologies")}}></li>
          </ul>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-6 col-12" style={{position:"relative"}}>
          <div style={{ display:"flex", verticalAlign:"bottom", height:"100%", alignItems:"center" }}>
            <div>
              <p className="section-title">{t("COMPUTER GRAPHICS")}</p>
              <p>{t("COMPUTER GRAPHICS TXT1")}</p>
              <p style={{fontWeight:"bold"}}>{t("This course will allow your kid")}:</p>

              <ul className="fc-list-1">
                <li>{t("learn how to create 3D models using the example of 3D parametric digital program Autodesk Inventor (AI)")};</li>
                <li>{t("to gain insight into of the 3D model and graphics")};</li>
                <li>{t("develop a high motivation to study both 3D models and 3D graphics")};</li>
                <li>{t("develop initial engineering literacy using 3D models and 3D graphics")};</li>
                <li>{t("learn to create 3D models and 3D graphics on your own")}.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-none d-md-block col-12 col-md-6">
          <div style={{display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"space-around"}}>
            <img style={{maxWidth:"600px", margin:"0 auto"}} src="/images/freecourse/graphic.png"/>          
            <img style={{maxWidth:"600px", margin:"0 auto"}} src="/images/freecourse/css.png"/>   
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-12">
          <img style={{ maxWidth:"60%", margin:"0 auto", display:"block"}} src="/images/freecourse/delimiter.png"/>
        </div>
      </div>


      <div className="row mb-5" >
        <div className="d-none d-md-block col-md-6">
          <div style={{ display:"flex", verticalAlign:"bottom", height:"100%", alignItems:"center" }}>
            <img src="/images/freecourse/animation.png"/>
          </div>
        </div>
        <div className="col-12 col-md-6" >
          &nbsp;
          <div style={{textAlign:"right"}}>
            <p className="section-title">{t("ANIMATION AND ANIMATED CARTOONS")}</p>
            <p>{t("The child is able to fantasize in various art forms")}.</p>
            <p>{t("But current circumstances dictate new demands on the individual")}:</p>
            <p>{t("Today active, communicative, creative individuals capable of scale are in demand")}.</p>
            <p>{t("Animation is a process of mastering new technologies. It has a great practical importance for the development of the creative abilities of the child. The art of animation is a collection of different activities, forming a harmoniously developed personality.")}</p>
            <p>{t("Learning the basics of animation, children become acquainted with leading professions of artist, director, screenwriter, cartoonist, operator, editor, sound engineer… and have the opportunity to live in these roles, through the realization and self-expression of each activity child will be able to:")}</p>

            <ul className="animation-list">
              <li>{t("DEVELOP CREATIVE ABILITIES THROUGH ANIMATION ACTIVITIES")};</li>
              <li>{t("TO LEARN THE BASICS OF VARIOUS ANIMATION ACTIVITIES")};</li>
              <li>{t("MAKE CORRECT VERBAL SPEECH")};</li>
              <li>{t("TO LEARN THE BASICS OF FINE ART AND DECORATIVE AND APPLIED ART")};</li>
              <li>{t("TO DEVELOP BASIC COGNITIVE PROCESSES - ATTENTION, PERCEPTION, THINKING, MEMORY, IMAGINATION, IN THE PROCESS OF ANIMATION ACTIVITY")}.</li>
            </ul>
          </div>
        
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-12">
          <img style={{ maxWidth:"60%", margin:"0 auto", display:"block"}} src="/images/freecourse/delimiter.png"/>
        </div>
      </div>


      <div className="row mb-5">
        <div className="col-md-6 col-12" style={{position:"relative"}}>
          <div style={{ display:"flex", verticalAlign:"bottom", height:"100%", alignItems:"center" }}>
            <div>
              <p className="section-title">{t("FILMING AND INSTALLATION")}</p>
              <p>{t("At the present time, there are many video opportunities (phones, cameras ) and it became necessary to process it at home.")}</p>
              <p>{t("The program allows peers to use technology and possibilities of modern installation equipment and development of creative abilities. The novelty of this program in the possibility of students to get acquainted with the latest computer programs and modern creative solutions. Distinguishing features of the educational program from others.")}</p>
              <p>{t("To organize an individual approach to each child. Our course will develop creative abilities and technical skills in dealing with non-linear mounting systems when creating a TV product.")}</p>
              <p style={{fontWeight:"bold"}}>{t("In one course your child will learn:")}</p>
              <ul className="fc-list-1">
                <li>{t("To work in the nonlinear editing system in the program «Adobe Premiere»")};</li>
                <li>{t("Learn the basic rules of image and sound editing")};</li>
                <li>{t("Master the art of editing, to further study the profession of director of television and to enter vocational education institutions")};</li>
                <li>{t("In the learning process will develop both creative, logical thinking and spatial imaging")};</li>
                <li>{t("Develops communicative qualities and the ability to communicate, as well as the ability to work")}.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-none d-md-block col-12 col-md-6">
          <img style={{MaxWidth:"600px", margin:"0 auto"}} src="/images/freecourse/video.png"/>
        </div>
      </div>

    </div>
  </section>
 

  <section className="vum-contacts">
    <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <p>+7 702 250 0001 <br/> +7 777 666 0611</p>
            <p>г.Алматы мкр.Аксай-5, д.25, офис В 28</p>
            <p>academy.elab.asia@gmail.com  <br/>
            https://elab.asia/ </p>
      </div>
      </div>
      </div>

  </section>

  <OurPartners />

  <Footer2 />

</div>
  );
    
}


export default Freecourse;
