import React from 'react'
import Footer from '../components/footer';
import Navbar from '../components/navbar';

class About extends React.Component{
    render(){
        return(
            <div>
                <Navbar />

                <section className="page-title-alt bg-primary position-relative">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <h1 className="text-white font-tertiary">О нас</h1>
      </div>
    </div>
  </div>
  <img src="images/illustrations/leaf-bg-top.png" alt="illustrations" className="bg-shape-1 w-100" />
  <img src="images/illustrations/dots-group-sm.png" alt="illustrations" className="bg-shape-2" />
  <img src="images/illustrations/leaf-yellow.png" alt="illustrations" className="bg-shape-3" />
  <img src="images/illustrations/leaf-orange.png" alt="illustrations" className="bg-shape-4" />
  <img src="images/illustrations/dots-group-cyan.png" alt="illustrations" className="bg-shape-5" />
  <img src="images/illustrations/leaf-cyan-lg.png" alt="illustrations" className="bg-shape-6" />
</section>

<section className="section pt-5">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.</p>
        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident.</p>
        <p>Deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
          architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia.</p>
      </div>
      <div className="col-md-4 text-center drag-lg-top">
        <div className="shadow-down mb-4">
          <img src="images/about/author.jpg" alt="author" className="img-fluid w-100 rounded-lg border-thick border-white" />
        </div>
        <img src="images/about/signature.png" alt="signature" className="img-fluid" />
        <h4>Johnathan Smith</h4>
      </div>
    </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row justify-content-around">
      <div className="col-lg-12 text-center">
        <h2 className="section-title">Work Process</h2>
      </div>
      <div className="col-lg-3 col-md-4 text-center hover-shadow pt-3">
        <div className="">
          <img src="images/icons/plan.png" className="mb-4" alt="icon" />
          <h4 className="mb-4">Research and Plan</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 text-center hover-shadow pt-3">
        <img src="images/icons/design.png" className="mb-4" alt="icon" />
        <h4 className="mb-4">Design and Develop</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua.</p>
      </div>
      <div className="col-lg-3 col-md-4 text-center hover-shadow pt-3">
        <img src="images/icons/print.png" className="mb-4" alt="icon" />
        <h4 className="mb-4">Deliver</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua.</p>
      </div>
    </div>
  </div>
</section>

<section className="section bg-cover" data-background="images/backgrounds/team-bg.png">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <h2 className="section-title">My Team</h2>
      </div>
      <div className="col-md-4 col-sm-6 mb-4 mb-md-0">
        <div className="card text-center">
          <img src="images/team/member-1.png" className="card-img-top" />
          <div className="card-body">
            <h4 className="card-title">Jack Schenziwe</h4>
            <p className="text-light font-secondary">Project Manager</p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 mb-4 mb-md-0">
        <div className="card text-center">
          <img src="images/team/member-2.png" className="card-img-top" />
          <div className="card-body">
            <h4 className="card-title">Jack Schenziwe</h4>
            <p className="text-light font-secondary">Project Manager</p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 mb-4 mb-md-0">
        <div className="card text-center">
          <img src="images/team/member-3.png" className="card-img-top" />
          <div className="card-body">
            <h4 className="card-title">Jack Schenziwe</h4>
            <p className="text-light font-secondary">Project Manager</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <h2 className="section-title">My Clients</h2>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <div className="client-logo-slider d-flex align-items-center">
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-1.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-2.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-3.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-4.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-5.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-1.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-2.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-3.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-4.png" alt="client-logo" /></a>
          <a href="#" className="text-center d-block outline-0 p-4"><img className="d-unset img-fluid"
              src="images/clients-logo/client-logo-5.png" alt="client-logo" /></a>
        </div>
      </div>
    </div>
  </div>
</section>

<Footer />
            </div>
        );
    }

}

export default About;