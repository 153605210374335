import React, {useEffect, useState} from 'react'
import Navbar from '../components/navbar';
import Footer2 from '../components/Footer2';
import OurPartners from '../components/ourPartners';
import './vum.css';
import ReactPlayer from "react-player"
import { useTranslation } from "react-i18next";


function Vum({ onLanguageChange }) {

  const { t } = useTranslation();

  const FacilityStyle = {
    padding:"10px 0 10px 30px"
  }

  return(

<div className="vum-page">
    
  <Navbar onLanguageChange={onLanguageChange} />

  <section className="page-title bg-primary position-relative">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center ">
        <h2 className="text-white font-tertiary">Varna University <br/>of Management</h2>
      </div>
    </div>
  </div>
  <img src="/images/illustrations/page-title.png" alt="illustrations" className="bg-shape-1 w-100 " />
  <img src="/images/illustrations/cSharp.png" alt="illustrations" className="bg-shape-2 " style={{maxWidth: '10%'}} />
  <img src="/images/illustrations/dots-cyan.png" alt="illustrations" className="bg-shape-3 " />
  <img src="/images/illustrations/leaf-orange.png" alt="illustrations" className="bg-shape-4 " />
  <img src="/images/illustrations/html.png" alt="illustrations" className="bg-shape-5" style={{WebkitTransform: 'rotate(0deg)', transform: 'rotate(0deg)', maxWidth: '10%'}} />
  <img src="/images/illustrations/dots-group-cyan.png" alt="illustrations" className="bg-shape-6 " />
  <img src="/images/illustrations/java.png" alt="illustrations" className="bg-shape-7 " style={{right: -0, bottom: -60, maxWidth: '10%'}}/>
  <img src="/images/illustrations/racho.png" alt="illustrations" className="bg-shape-7 " style={{left: 200, top: 150, maxWidth: '10%'}}/>
  <img src="/images/illustrations/bars.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{left: 550, top: 10, maxWidth: '10%'}}/>
  <img src="/images/illustrations/varna.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{right: 40, bottom: 200, maxWidth: '20%'}}/>
</section>

  <section className="section" style={{paddingBottom:"0px"}}>
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
            <h1 className="montserrat" style={{color:"#17c5b8", textAlign:"center", fontSize:"42px"}}>{t("Education in Europe")}</h1>
            <h2 className="montserrat mb-20" style={{color:"#4788bf", textAlign:"center", fontSize:"36px"}}>{t("Varna University of Management")}</h2>
        </div>
      </div>

        <div className="row mb-5">
          <div className="col-md-6 col-12" style={{position:"relative"}}>
            <div style={{ display:"flex", verticalAlign:"bottom", height:"100%", alignItems:"center" }}>
              <div>
                <p style={{fontWeight:"bold"}}>{t("Varna University of Management")}</p>
                <p>{t("VUM TXT")}</p>
                <p>&nbsp;</p>
                <p>{t("Unique opportunity")}:</p>
                <p dangerouslySetInnerHTML={{__html:t("Combine a senior year with European University") }}></p>
              </div>
            </div>
          </div>
          <div className="col">
            <img style={{width:"600px", margin:"0 auto"}} src="/images/vum/student.png"/>
          </div>
        </div>


        <div className="row mb-5" >
          <div className="col-md-6 col-12">
            <div style={{ display:"flex", verticalAlign:"bottom", height:"100%", alignItems:"center" }}>
              <img src="/images/vum/3students.png"/>
            </div>
          </div>
          <div className="col-6" >
            &nbsp;
            <div style={{padding:"40px"}}>
              <p style={{fontWeight:"bold"}}>{t("Varna University of Management")}</p>
              <p style={{fontWeight:"500"}}>{t("Allows to be admitted to the programs without exams")}:</p>


              <div className="facility" style={{backgroundImage:"url(/images/vum/icon_it.png)"}}>Информационные технологии</div>
              <div className="facility" style={{backgroundImage:"url(/images/vum/icon_econom.png)"}}>Экономика и финансы</div>
              <div className="facility" style={{backgroundImage:"url(/images/vum/icon_manage.png)"}}>Менеджмент</div>
              <div className="facility" style={{backgroundImage:"url(/images/vum/icon_business.png)"}}>Бизнес-администрация</div>
              <div className="facility" style={{backgroundImage:"url(/images/vum/icon_hotel.png)"}}>Гостиничное дело</div>
              <div className="facility" style={{backgroundImage:"url(/images/vum/icon_kulin.png)"}}>Кулинария и кондитерское дело</div>
      


            </div>
          
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <p style={{textTransform:"uppercase", textAlign:"center", fontWeight:"bold"}}>Дорогой Друг!</p>

<p style={{color:"#ffc000", fontWeight:"bold"}}>Если Вы</p>

<ul className="vum-want">
    <li>хотите стать востребованным, высококвалифицированным специалистом? </li>
    <li>хотите поступить без ЕНТ в лучший университет Европы, обучаясь уже сейчас в 11 классе?</li>
    <li>хотите получить качественное образование у профессиональных преподавателей английского языка?</li>
    <li>хотите свободно владеть английским языком, стать студентом І курса Варненского университета менеджмента (VUM BR) и получить качественное образование от квалифицированных преподавателей г. Алматы?</li>
    <li>хотели бы после окончания университета дополнительно получить второй диплом Британского вуза (Cardiff Metropolitan University), который является партнером Варненского Университета Менеджмента? </li>
</ul>

<p style={{color:"#ffc000", textTransform:"uppercase", fontWeight:"bold", fontStyle:"italic" }}>В таком случае, Вы можете связаться с нами!<br/>	
Места ограничены, не упуская отличных возможностей, Вы можете учиться на выбор!</p>
  


<p style={{fontWeight:"bold"}}><span style={{color:"#636363"}}>ОФЛАЙН,</span> <span style={{color:"#37a411"}}>ОНЛАЙН</span></p>
            
          </div>
        </div>
     
    </div>
  </section>

  <section>
    <div className="container">
      <div className="row mb-5">
        <div className="col-12 col-md-6">         
          <ReactPlayer url="https://youtu.be/CF5ISru0OVo" width="100%"/>
        </div>
        <div className="col-12 col-md-6">
          <ReactPlayer url="https://youtu.be/-Dv_cSpFFHQ" width="100%"/>
        </div>
      </div>
    </div>
  </section>

  

  <section className="vum-contacts">
    <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <p>+7 702 250 0001 </p>
            <p>г.Алматы мкр.Аксай-5, д.25, офис В 28</p>
            <p>academy.elab.asia@gmail.com  <br/>
            https://elab.asia/ </p>
      </div>
      </div>
      </div>

  </section>

  <OurPartners />

  <Footer2 />

</div>
  );
    
}


export default Vum;
