import React, { useEffect, useState }  from 'react'
import {Link} from 'react-router-dom'

import logo from '../logo.svg';

import { withTranslation } from "react-i18next";
import { Translation, Trans } from "react-i18next";

class Welcome extends React.Component{

  
  render(){
  
      return(
        <Translation> 
          {(t) => (
<section className="hero-area bg-primary" id="parallax">
  <div className="container">
    <div className="row">
      <div className="col-lg-11 mx-auto">
          <h1 className="text-white font-tertiary" dangerouslySetInnerHTML={ { __html: t("welcome_header_text") }}></h1>
      </div>
    </div>
  </div>
  <div className="layer-bg w-100">
    <img className="img-fluid w-100" src="images/illustrations/leaf-bg.png" alt="bg-shape" />
  </div>
  <div className="layer" id="l2">
    <img src="images/illustrations/dots-cyan.png" alt="bg-shape" />
  </div>
  <div className="layer" id="l3">
    <img src="images/illustrations/cSharp.png" alt="bg-shape" style={{maxWidth: '60%'}} />
  </div>
  <div className="layer" id="l4">
    <img src="images/illustrations/dots-orange.png" alt="bg-shape" />
  </div>
  <div className="layer" id="l5">
    <img src={logo} className="App-logo" alt="logo" />
  </div>
  <div className="layer" id="l6">
    <img src="images/illustrations/python.png" alt="bg-shape" style={{maxWidth: '60%'}} />
  </div>
  <div className="layer" id="l7">
    <img src="images/illustrations/dots-group-orange.png" alt="bg-shape" />
  </div>
  <div className="layer d-none d-sm-block" id="l8">
    <img src="images/illustrations/js.png" alt="bg-shape" style={{maxWidth: '60%'}}/>
  </div>
  <div className="layer d-none d-sm-block" id="l9">
    <img src="images/illustrations/html.png" alt="bg-shape" style={{maxWidth: '60%'}}/>
  </div>
  <ul className="list-unstyled ml-5 mt-3 position-relative zindex-1">
    <li className="mb-3"><a className="text-white" href="https://www.facebook.com/elabasia_digital_academy-105324734595469/"><i className="ti-facebook"></i></a></li>
    <li className="mb-3"><a className="text-white" href="https://www.instagram.com/elab.asia/"><i className="ti-instagram"></i></a></li>
    <li className="mb-3"><a className="text-white" href="https://www.youtube.com/channel/UCjwLOs7tgaZ1ArqTv10r8Zw/featured"><i className="ti-youtube"></i></a></li>
  </ul>

  <div className="layer d-none d-sm-block" style={{top: "150px", left: "100px"}}>
    <img src="images/illustrations/racho.png" alt="bg-shape" style={{maxWidth: '60%'}}/>
  </div>

  <div className="layer d-none d-sm-block" style={{top: "10px", left: "450px"}}>
    <img src="images/illustrations/bars.png" alt="bg-shape" style={{maxWidth: '60%'}}/>
  </div>

  <div className="layer d-none d-sm-block" style={{right: "-200px", bottom: "400px"}}>
    <img src="images/illustrations/varna.png" alt="bg-shape" style={{maxWidth: '50%'}}/>
  </div>

</section>
)}
</Translation>
        );
    }
}

{/* export default Welcome; */}
export default withTranslation()(Welcome);