import React from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Coursenav() {

    const { t } = useTranslation();
    
    return(
<div className="btn-group btn-group-toggle d-flex flex-wrap justify-content-center" data-toggle="buttons">

<Link to={{pathname: '/courses-list-group/0', fromDashboard: false}}>
<label className="btn btn-sm btn-primary">
<input type="radio" name="shuffle-filter" value="course_group-1" style={{position: 'absolute', clip: 'rect(0,0,0,0)', pointerEvents: 'none'}}/>{t("Global competences")}</label>
</Link> 
<Link to={{pathname: '/courses-list-group/1', fromDashboard: false}}>
<label className="btn btn-sm btn-primary">
<input type="radio" name="shuffle-filter" value="course_group-2" style={{position: 'absolute', clip: 'rect(0,0,0,0)', pointerEvents: 'none'}}/>{t("Programming")}</label>
</Link>
<Link to={{pathname: '/courses-list-group/2', fromDashboard: false}}>
<label className="btn btn-sm btn-primary">
<input type="radio" name="shuffle-filter" value="design" style={{position: 'absolute', clip: 'rect(0,0,0,0)', pointerEvents: 'none'}}/>{t("Courses for teachers")}</label>
</Link>
<Link to={{pathname: '/courses-list-group/3', fromDashboard: false}}>
<label className="btn btn-sm btn-primary">
<input type="radio" name="shuffle-filter" value="design" style={{position: 'absolute', clip: 'rect(0,0,0,0)', pointerEvents: 'none'}}/>Курсы для предпринимателей
</label>
</Link>

</div>
        )
    
}

export default Coursenav;