import React from 'react'
import {Link} from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'
import Almagul from './Almagul.jpeg'
import Roza from './Roza.jpeg'
import RozaS from './RozaS.jpg'
import Konirbayev from './konirbayev_b.jpg'
import Erbolat from './Erbolat.jpg'
import Orley from './../assets/images/reviews/orley_asel_z.jpeg'
import '../App.css'

import { useTranslation } from "react-i18next";


function  OurReviews() {
  
  const { t } = useTranslation();

        return(
            <section className="section bg-primary position-relative testimonial-bg-shapes">
            <div className="container">
              <div className="row">
              <div className="col-12 text-center"><h2 className="section-title text-white mb-5">{t("Feedback")}</h2></div>
              <Carousel fade>


  <Carousel.Item>
  <div className="div.carousel-inner">
  <div style={{textAlign: 'center'}}><img className="img-fluid rounded-circle mb-4 d-inline-block" src={Orley}
                      alt="client-image" style={{width: '25%'}}/></div>
    <div className="col-lg-10 mx-auto testimonial-slider">
                  <div className="text-center testimonial-content">
                    <p className="text-white mb-4">{t("Feedback_2_TXT")}</p>
                    <h4 className="text-white">Асель Зейнуловна Мухаметжанова</h4>
                    <h6 className="text-light mb-4">{t("Feedback_2_POS")}</h6>
                  </div>
                </div>
                </div>               
  </Carousel.Item>

  <Carousel.Item>
  <div className="div.carousel-inner">
  <div style={{textAlign: 'center'}}><img className="img-fluid rounded-circle mb-4 d-inline-block" src={Roza}
                      alt="client-image" style={{width: '25%'}}/></div>

    <div className="col-lg-10 mx-auto testimonial-slider">
      <div className="text-center testimonial-content">
        <p className="text-white mb-4" dangerouslySetInnerHTML={{__html:t("Feedback_3_TXT")}} ></p>
        
        <h4 className="text-white">Жаева Роза Райхановна, </h4>
        <h6 className="text-light mb-4">{t("Feedback_3_POS")}</h6>
      </div>
      
    </div>
    </div>
  </Carousel.Item>

  <Carousel.Item>
  <div className="div.carousel-inner">
  <div style={{textAlign: 'center'}}><img className="img-fluid rounded-circle mb-4 d-inline-block" src={RozaS}
                      alt="client-image" style={{width: '23%' , height:'23%'}}/></div>

    <div className="col-lg-10 mx-auto testimonial-slider">
                  <div className="text-center testimonial-content">
                    <p className="text-white mb-4" dangerouslySetInnerHTML={{__html:t("Feedback_4_TXT")}}></p>
                    
                    <h4 className="text-white">Садыкова Роза Шариповна, </h4>
                    <h6 className="text-light mb-4">{t("Feedback_4_POS")}</h6>
                  </div>
                  
                </div>
                </div>
  </Carousel.Item>
  <Carousel.Item>
  <div className="div.carousel-inner">
  <div style={{textAlign: 'center'}}><img className="img-fluid rounded-circle mb-4 d-inline-block" src={Konirbayev}
                      alt="client-image" style={{width: '24%' , height:'24%'}}/></div>

    <div className="col-lg-10 mx-auto testimonial-slider">
                  <div className="text-center testimonial-content">
                    <p className="text-white mb-4" dangerouslySetInnerHTML={{__html:t("Feedback_5_TXT")}}></p>

                    <h4 className="text-white"> Конирбаев Баян Кайратович, </h4>
                    <h6 className="text-light mb-4">{t("Feedback_5_POS")}</h6>
                  </div>
                </div>
                </div>
  </Carousel.Item>
  <Carousel.Item>
  <div className="div.carousel-inner">
  <div style={{textAlign: 'center'}}><img className="img-fluid rounded-circle mb-4 d-inline-block" src={Erbolat}
                      alt="client-image" style={{width: '24%' , height:'24%'}}/></div>

    <div className="col-lg-10 mx-auto testimonial-slider">
                  <div className="text-center testimonial-content">
                    <p className="text-white mb-4" dangerouslySetInnerHTML={{__html:t("Feedback_6_TXT")}}></p>
                    <h4 className="text-white"> Саурыков Ерболат Байузакович, </h4>
                    <h6 className="text-light mb-4">{t("Feedback_6_POS")}</h6>
                  </div>
                  
                </div>
                </div>
  </Carousel.Item>
</Carousel>
              </div>
            </div>
            <img src="images/backgrounds/map.png" alt="map" className="img-fluid bg-map" />
            
          </section>
        );
    
}

export default OurReviews;