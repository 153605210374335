import React from 'react';
import { withTranslation } from 'react-i18next';

class PageHeader extends React.Component{

  constructor(props) {
    super(props);
  }

  render() {
    var title = this.props.title;
    if (typeof title == typeof undefined) {
      title = this.props.t("COURSES");
    }
      return(
<section className="page-title bg-primary position-relative" >
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="text-white font-tertiary">{ title }</h1>
      </div>
    </div>
  </div>
  <img src="/images/illustrations/page-title.png" alt="illustrations" className="bg-shape-1 w-100" />
  <img src="/images/illustrations/cSharp.png" alt="illustrations" className="bg-shape-2" style={{maxWidth: '10%'}} />
  <img src="/images/illustrations/dots-cyan.png" alt="illustrations" className="bg-shape-3" />
  <img src="/images/illustrations/leaf-orange.png" alt="illustrations" className="bg-shape-4" />
  <img src="/images/illustrations/html.png" alt="illustrations" className="bg-shape-5" style={{WebkitTransform: 'rotate(0deg)', transform: 'rotate(0deg)', maxWidth: '10%'}} />
  <img src="/images/illustrations/dots-group-cyan.png" alt="illustrations" className="bg-shape-6 d-none d-sm-block" />
  <img src="/images/illustrations/java.png" alt="illustrations" className="bg-shape-7 d-none d-sm-block" style={{right: -0, bottom: -60, maxWidth: '10%'}}/>
</section>
        )
    }
}

export default withTranslation()(PageHeader);