import React from 'react'
import '../App.css'
import { useTranslation } from "react-i18next";


function  OurPartners() {
  const { t } = useTranslation();    

    return(
        <section className="section pb-0">
        <div className="container">
            <div className="col-12 text-center">
                <h2 className="section-title text-black mb-5">{t("Our Partners")}</h2>
            </div>

            <div className="client-logo-slider d-flex flex-wrap justify-content-center">

                <a href="http://www.rapo.kz/ru/" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-1.png" alt="client-logo" /></a>
                <a href="https://omgpu.ru/" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-2.png" alt="client-logo" /></a>
                <a href="http://almaty.daryny.kz/" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-3.png" alt="client-logo" /></a>
                <a href="https://intc.kz/ru/" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-4.png" alt="client-logo" /></a>
                <a href="http://htii.kz/ru/" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-5.png" alt="client-logo" /></a>
                <a href="https://korkyt.edu.kz/index.php?lang=ru" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-6.jpeg" alt="client-logo" /></a>
                <a href="https://ppu.edu.kz/" target="_blank" className="text-center p-4"><img className="d-unset img-fluid"
                    src="/images/clients-logo/client-logo-7.jpeg" alt="client-logo" /></a>
            </div>
        </div>
    </section>
    );
    
}

export default OurPartners;