import {Link} from 'react-router-dom'
import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";


function Navbar({ onLanguageChange }) {
  const { t } = useTranslation();
 
  return(

<div>
  <header className="navigation fixed-top">
    <nav className="navbar navbar-expand-lg navbar-dark">
      <a className="navbar-brand font-tertiary h3" href="/"><strong>ELAB.ASIA</strong></a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
        aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse text-center" id="navigation">

        <LanguageSwitcher onChange={onLanguageChange} />

        {/* <ul className="nav navbar-nav navbar-right lang-selector">
      	  <li><a href="#" lang="en" onClick={languageHandler}>EN</a></li>
      	  {/* <li><a href="#" lang="kz" onClick={languageHandler}>KZ</a></li> /}
      	  <li><a href="#" lang="ru" onClick={languageHandler}>RU</a></li>
        </ul> */}

        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <a href="/" className="nav-link"> { t("main_menu_home") } </a>
          </li>
          <li className="nav-item">
            <Link to={{pathname: '/vum', fromDashboard: false}} className="nav-link"> { t("main_menu_education_in_eu") } </Link>
          </li>
          <li className="nav-item">
            <Link to={{pathname: '/post-list', fromDashboard: false}} className="nav-link">{ t("main_menu_events") }</Link>
          </li>
          <li className="nav-item">
            <Link to={{pathname: '/courses-list', fromDashboard: false}} className="nav-link">{ t("main_menu_courses") }</Link>
          </li>
          <li className="nav-item">
            <Link to={{pathname: '/freecourse', fromDashboard: false}} className="nav-link">{ t("main_menu_free_courses") }</Link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</div>
        );
    
}

export default Navbar;